"use client";

import React, { forwardRef } from "react";
import { useTheme } from "@chakra-ui/react";
import { Select, SelectComponent } from "chakra-react-select";

export const ReactSelect = forwardRef(
  function ReactSelectComponent(props, ref) {
    const theme = useTheme();

    return (
      <Select
        ref={ref}
        useBasicStyles
        noOptionsMessage={() => "選択肢はありません"}
        {...props}
        chakraStyles={{
          container: (base) => ({
            ...base,
            width: "100%",
            bgColor: theme.colors.white,
            cursor: "pointer",
          }),
          control: (base) => ({
            ...base,
            borderRadius: 6,
            _focus: {
              _readOnly: {
                borderColor: "gray.300",
              },
            },
          }),
          valueContainer: (base) => ({
            ...base,
            marginInline: "0px",
            paddingInlineStart: "12px",
            paddingInlineEnd: "0px",
          }),
          menu: (base) => ({
            ...base,
            zIndex: theme.zIndices.dropdown,
          }),
          option: (base) => ({
            ...base,
            paddingInlineStart: "12px",
          }),
          ...props.chakraStyles,
        }}
      />
    );
  },
  // FIXME: forwardRefしたReactSelectの型を返す
) as SelectComponent;
