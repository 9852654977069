import { HStack, StackProps, VStack } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  horizontal?: boolean;
} & StackProps;

export const PageContent = ({ children, horizontal, ...rest }: Props) => (
  <>
    {horizontal ? (
      <HStack
        paddingX="24px"
        paddingY="16px"
        width="100%"
        maxWidth="100%"
        direction="row"
        justify="flex-start"
        align="flex-start"
        spacing="40px"
        overflowX="auto"
        height="100%"
        {...rest}
      >
        {children}
      </HStack>
    ) : (
      <VStack
        paddingX="24px"
        paddingY="16px"
        paddingRight={
          "calc(24px - calc(calc(100vw - var(--sidebar-width)) - 100%))"
        }
        width="100%"
        maxWidth="100%"
        spacing={2}
        align="flex-start"
        {...rest}
      >
        {children}
      </VStack>
    )}
  </>
);
