import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  Tag,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import {
  UseFormRegister,
  FieldValues,
  RegisterOptions,
  FieldErrors,
  Path,
  UseFormGetValues,
  UseFormSetValue,
  PathValue,
  ControllerProps,
} from "react-hook-form";
import { ReactSelect } from "~/src/components/atoms/ReactSelect";
import { MdInfoOutline } from "react-icons/md";

export type SelectOption = {
  value: string | number;
  label: string | number;
};

type FormItem = {
  controlName: string;
  validation: RegisterOptions<FieldValues>;
  label: string | React.ReactElement;
  showErrors: boolean;
  controlType:
    | "input"
    | "select"
    | "textarea"
    | React.ReactElement<ControllerProps>;
  placeholder?: string;
  inputType?:
    | "date"
    | "time"
    | "input"
    | "tel"
    | "email"
    | "number"
    | "password";
  disabled?: boolean;
  selectOptions?: SelectOption[];
  min?: number;
  max?: number;
  step?: string;
  tooltip?: string;
};

type VariableFormItem = FormItem | FormItem[];

export type FormControls = FormItem[] | VariableFormItem[];

type FormGroup<T extends FieldValues> = {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  items: FormControls;
  setValue?: UseFormSetValue<T>;
  getValues?: UseFormGetValues<T>;
};

export const RequiredIndicator = () => {
  return (
    <Tag
      marginLeft="4px"
      variant="outline"
      borderColor="red.400"
      borderRadius="2px"
      colorScheme="red"
      px="4px"
      py="3px"
      fontSize="10px"
      fontWeight="bold"
      size="sm"
    >
      必須
    </Tag>
  );
};

const ControlWrapper = ({
  control,
  errors,
  children,
}: {
  control: FormItem;
  errors: FieldErrors;
  children: React.ReactNode;
}) => (
  <FormControl
    id={control.controlName}
    key={control.controlName}
    isInvalid={!!errors[control.controlName]}
    isRequired={!!control.validation.required}
  >
    <HStack mb="4px">
      <FormLabel
        m={0}
        fontSize="16px"
        fontWeight="bold"
        color="gray.700"
        height="24px"
        alignItems="center"
        display="flex"
        flexDir="row"
        requiredIndicator={<RequiredIndicator />}
      >
        {control.label}
      </FormLabel>
      {control.tooltip && (
        <Tooltip
          hasArrow
          placement="top-start"
          py="8px"
          px="12px"
          borderRadius="4px"
          label={control.tooltip}
          maxWidth={"none"}
        >
          <Box h={"18px"} w={"18px"}>
            <Icon as={MdInfoOutline} boxSize={"18px"} color={"gray.500"} />
          </Box>
        </Tooltip>
      )}
    </HStack>

    {children}

    {control.showErrors &&
      errors &&
      errors[control.controlName] &&
      errors[control.controlName]!.types &&
      Object.entries(errors[control.controlName]!.types!).map(
        ([type, message]) => (
          <FormErrorMessage key={type}>{message}</FormErrorMessage>
        ),
      )}
  </FormControl>
);

export const FormWrapper = <T extends FieldValues>({
  register,
  items,
  errors,
  getValues,
  setValue,
}: FormGroup<T>) => {
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  const getInputType = useCallback(
    (item: FormItem) => {
      switch (item.controlType) {
        case "input":
          return (
            <Input
              maxLength={item.max}
              minLength={item.min}
              step={item.step}
              disabled={item.disabled}
              placeholder={item.placeholder}
              borderColor={errors[item.controlName] ? "red.500" : "gray.200"}
              type={item.inputType || "input"}
              {...register(item.controlName as Path<T>, { ...item.validation })}
            />
          );
        case "textarea":
          return (
            <Textarea
              disabled={item.disabled}
              paddingStart="8px"
              paddingEnd="2.5px"
              paddingTop="8px"
              paddingBottom="2px"
              height="100px"
              alignSelf="stretch"
              borderColor="gray.200"
              borderStartWidth="1px"
              borderEndWidth="1px"
              borderTopWidth="1px"
              borderBottomWidth="1px"
              placeholder={item.placeholder}
              {...register(item.controlName as Path<T>, { ...item.validation })}
            />
          );
        case "select":
          return (
            <ReactSelect
              {...register(item.controlName as Path<T>, { ...item.validation })}
              placeholder={item.placeholder}
              options={item.selectOptions}
              isDisabled={item.disabled}
              value={
                getValues &&
                item.selectOptions &&
                item.selectOptions.find(
                  (o) => o.value === getValues(item.controlName as Path<T>),
                )
              }
              onChange={(option) => {
                if (setValue) {
                  setValue(
                    item.controlName as Path<T>,
                    (option?.value as PathValue<T, Path<T>>) ??
                      ("" as PathValue<T, Path<T>>),
                  );
                }
              }}
            />
          );

        default:
          return item.controlType;
      }
    },
    [errors, getValues, register, setValue],
  );
  return initialized ? (
    <Stack
      justify="flex-start"
      align="flex-start"
      spacing="24px"
      alignSelf="stretch"
    >
      {items.map((item) => {
        if (Array.isArray(item)) {
          return (
            <Stack
              key={item[0].controlName}
              direction="row"
              justify="flex-start"
              align="flex-start"
              spacing="24px"
              alignSelf="stretch"
            >
              {item.map((groupItem) => (
                <ControlWrapper
                  key={groupItem.controlName}
                  control={groupItem}
                  errors={errors}
                >
                  {getInputType(groupItem)}
                </ControlWrapper>
              ))}
            </Stack>
          );
        }
        return (
          <ControlWrapper key={item.controlName} control={item} errors={errors}>
            {getInputType(item)}
          </ControlWrapper>
        );
      })}
    </Stack>
  ) : null;
};
