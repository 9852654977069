"use client";
import React, { useEffect } from "react";
import {
  VStack,
  Button,
  Card,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  // Link as ExternalLink,
} from "@chakra-ui/react";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useAuth } from "~/contexts/AuthContext";
import { useToast } from "~/src/hooks/useToast";
import { Route } from "next";
import { PageContent } from "~/src/components/organisms/PageContent";
import { RequiredIndicator } from "~/src/components/organisms/Form";

const schema = z.object({
  email: z
    .string()
    .refine((value) => value.length > 0, {
      message: "メールアドレスを入力してください",
    })
    .refine(
      (value) => {
        if (value.length > 0) {
          return z.string().email().safeParse(value).success;
        }
        return true;
      },
      {
        message: "有効なメールアドレスを入力してください",
      },
    ),
  password: z.string().min(1, { message: "パスワードを入力してください" }),
});

type FormValues = z.infer<typeof schema>;

const SignInPage = () => {
  const { login, loggedIn } = useAuth();
  const router = useRouter();
  const searchParams = useSearchParams();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    criteriaMode: "all",
    resolver: zodResolver(schema),
  });

  const onSubmit: Parameters<typeof handleSubmit>[0] = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      toast({
        title: "ログインに失敗しました。",
        description: "メールアドレスまたはパスワードが正しくありません。",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (loggedIn) {
      const redirect = searchParams.get("redirect");
      // スラッシュ始まりでない場合は無視する
      if (redirect?.match(/^\//)) {
        const navigateTo: Route = redirect as Route;
        router.replace(navigateTo);
      } else {
        const navigateTo: Route = "/customer";
        router.push(navigateTo);
      }
    }
    return () => {};
  }, [loggedIn, router, searchParams]);
  return (
    <VStack width="100%" height="100%" spacing={0}>
      <PageContent height="100%" alignItems="center" paddingTop="112px">
        <Card p="40px" variant="outline" w="100%" maxWidth="400px">
          <VStack width="full" spacing="40px">
            <Image src="/images/logo.svg" alt="logo" width={320} height={45} />
            <VStack spacing="16px" width="100%">
              <FormControl isRequired width="100%" isInvalid={!!errors.email}>
                <FormLabel
                  fontSize="16px"
                  fontWeight="bold"
                  color="gray.700"
                  height="24px"
                  alignItems="center"
                  display="flex"
                  flexDir="row"
                  requiredIndicator={<RequiredIndicator />}
                >
                  メールアドレス
                </FormLabel>
                <Input
                  {...register("email")}
                  type="text"
                  borderRadius="6px"
                  backgroundColor="white"
                  borderColor={errors.email ? "red.500" : "gray.200"}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired w="100%" isInvalid={!!errors.password}>
                <FormLabel
                  fontSize="16px"
                  fontWeight="bold"
                  color="gray.700"
                  height="24px"
                  alignItems="center"
                  display="flex"
                  flexDir="row"
                  requiredIndicator={<RequiredIndicator />}
                >
                  パスワード
                </FormLabel>
                <Input
                  {...register("password")}
                  type="password"
                  borderRadius="6px"
                  backgroundColor="white"
                  borderColor={errors.password ? "red.500" : "gray.200"}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
            <Button
              w="full"
              isLoading={isSubmitting}
              bg="blue.500"
              color="white"
              onClick={handleSubmit(onSubmit)}
            >
              ログイン
            </Button>
            {/* 今の段階はページないので非対応 */}
            {/* <ExternalLink color="theme.accent" isExternal target="_blank">
              パスワードをお忘れ方
            </ExternalLink> */}
          </VStack>
        </Card>
      </PageContent>
    </VStack>
  );
};

export default SignInPage;
